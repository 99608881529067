























import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Col, Row, Button, NavBar, Grid, GridItem } from "vant";
import SelectSeatTitle from "@/components/SelectSeat/SelectSeatTitle.vue";
import SelectSeatDescription from "@/components/SelectSeat/SelectSeatDescription.vue";
import SelectSeatPrice from "@/components/SelectSeat/SelectSeatPrice.vue";
import SeatSelector from "@/components/SelectSeat/SeatSelector/SeatSelector.vue";

@Component({
  components: {
    [Col.name]: Col,
    [Row.name]: Row,
    [Button.name]: Button,
    [NavBar.name]: NavBar,
    [Grid.name]: Grid,
    [GridItem.name]: GridItem,
    SelectSeatTitle,
    SelectSeatDescription,
    SelectSeatPrice,
    SeatSelector,
  },
})
export default class SelectSeat extends Mixins(Mixin) {
  showPrice: good.ShowPriceDto = {
    priceDtos: [],
  }; // 演出票价信息
  cdnUrl = ""; // 座位底图的 CDN 地址

  get prices(): Array<good.PriceDto> {
    let priceDtos = this.showPrice.priceDtos;
    return priceDtos || [];
  }

  refresh(): void {
    let query = this.$route.query;
    let showId = Number(query.showId);
    let sectionId = Number(query.sectionId);
    let priceId = Number(query.priceId);
    let productId = query.productId;
    if (isNaN(showId) || isNaN(sectionId) || !productId) {
      this.$toast("非法请求");
    } else {
      this.SelectSeatModule.SET_showId(showId);
      this.SelectSeatModule.SET_sectionId(sectionId);
      this.SelectSeatModule.SET_productId(String(productId));
      if (!isNaN(priceId)) {
        this.SelectSeatModule.SET_highlightPriceId(priceId);
      }
    }
    let seatSelectorRow = (this.$refs["seat-selector-row"] as Row)
      .$el as HTMLElement;
    if (seatSelectorRow) {
      this.SelectSeatModule.SET_seatSelectorRowOffsetTop(
        seatSelectorRow.offsetTop
      );
    }

    // nextTick 是为了保证各个变量已经赋值完成后再执行
    this.$nextTick(() => {
      // 获取票价
      this.InterfaceCacheModule.getData({
        cacheKey: "show.getShowPrice",
        apiMethod: this.$api.goodApi.show.getShowPrice,
        params: [this.SelectSeatModule.showId],
        expireInterval: 28800, //缓存时间 480 分钟
        randInterval: 300, //5分钟随机时间
        callback: ({ data }) => {
          this.showPrice = data;
          const seatSelector = this.$refs["seat-selector"] as SeatSelector;
          if (seatSelector) {
            // 获取完票价再刷新选座组件，否则可能会出现座位没颜色的情况
            seatSelector.refresh();
          }
        },
      });
    });
  }

  submit(data: order.AliSliderCaptchaDto): void {
    this.$emit("submit", data);
  }
}
