






import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";

@Component({
  components: {},
})
export default class SelectSeatDescription extends Mixins(Mixin) {
  // 演出票价信息
  @Prop({
    type: Object,
    default() {
      return {
        priceDtos: [],
      };
    },
  })
  showPrice!: good.ShowPriceDto;
}
