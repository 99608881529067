



































import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Icon, Dialog } from "vant";
import EventBus from "@/utils/eventBus";

@Component({
  components: { [Icon.name]: Icon, [Dialog.Component.name]: Dialog.Component },
})
export default class SelectSeatPrice extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default() {
      return [];
    },
  })
  prices!: Array<good.PriceDto>;

  highlightPrice(ticketPriceId?: number): void {
    if (!ticketPriceId && ticketPriceId != 0) {
      return;
    }
    if (
      this.SelectSeatModule.hasAvailableSeatPriceIds.indexOf(ticketPriceId) < 0
    ) {
      // 该票档已售罄
      Dialog.alert({
        title: "提示",
        message: "该价格座位已售罄，看看其他吧",
      });
    } else {
      let oldHighlightPriceId = this.SelectSeatModule.highlightPriceId;
      let fixedTicketPriceId = ticketPriceId == undefined ? -1 : ticketPriceId;
      let selectedPriceId =
        this.SelectSeatModule.highlightPriceId == fixedTicketPriceId
          ? -1
          : fixedTicketPriceId;
      this.SelectSeatModule.SET_highlightPriceId(selectedPriceId);
      EventBus.$emit("selectPriceEvent", oldHighlightPriceId);
    }
  }
}
