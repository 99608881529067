



























import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import CanvasSeats from "@/components/SelectSeat/SeatSelector/Seats/CanvasSeats.vue";

@Component({ components: { CanvasSeats } })
export default class MiniSeatMap extends Mixins(Mixin) {
  screenWidth = window.document.documentElement.clientWidth; // 座位视口宽度
  screenHeight = window.document.documentElement.clientWidth; // 座位视口高度
  miniMapWidth = this.screenWidth * 0.5;

  get miniMapHeight(): number {
    let canvasHeight = this.SelectSeatModule.canvasHeight;
    if (!canvasHeight) {
      return 0;
    }
    let widthCalc = this.widthCalc;
    if (isNaN(widthCalc)) {
      return 0;
    }
    return canvasHeight * widthCalc;
  }

  get widthCalc(): number {
    return this.miniMapWidth / this.SelectSeatModule.canvasWidth;
  }

  /**
   * 迷你座位图与缩放后的座位图宽度的比例
   */
  get miniMapWidthRatio(): number {
    let canvasWidth = this.SelectSeatModule.canvasWidth;
    if (isNaN(canvasWidth)) {
      return 0;
    }
    return (
      this.miniMapWidth /
      (canvasWidth * this.SelectSeatModule.seatMapMatrixScale)
    );
  }

  /**
   * 迷你座位图与座位图高度的比例
   */
  get miniMapHeightRatio(): number {
    let canvasHeight = this.SelectSeatModule.canvasHeight;
    if (isNaN(canvasHeight)) {
      return 0;
    }
    return (
      this.miniMapHeight /
      (canvasHeight * this.SelectSeatModule.seatMapMatrixScale)
    );
  }

  get loopWidth(): number {
    let miniMapWidthRatio = this.miniMapWidthRatio;
    if (!miniMapWidthRatio) {
      return 0;
    }
    return this.screenWidth * miniMapWidthRatio;
  }

  get loopHeight(): number {
    let miniMapHeightRatio = this.miniMapHeightRatio;
    if (!miniMapHeightRatio) {
      return 0;
    }
    return this.SelectSeatModule.seatWrapperHeight * miniMapHeightRatio;
  }

  get loopLeft(): number {
    return (
      (this.miniMapWidth - this.loopWidth) / 2 -
      this.SelectSeatModule.seatMapMatrixX * this.miniMapWidthRatio
    );
  }

  get loopTop(): number {
    return (
      (this.miniMapHeight - this.loopHeight) / 2 -
      this.SelectSeatModule.seatMapMatrixY * this.miniMapHeightRatio
    );
  }

  refresh(): void {
    this.$nextTick(() => {
      let canvasSeats = this.$refs["mini-canvas-seats"];
      if (canvasSeats) {
        (canvasSeats as CanvasSeats).paint();
      }
    });
  }

  /**
   * 因为只在移动时才显示迷你地图
   * 所以在第一次绘制完座位图后隐藏小地图，之后由移动座位图事件触发显示
   */
  paintComplete(): void {
    this.SelectSeatModule.SET_isShowMiniSeatMap(false);
  }
}
