var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('canvas',{class:'seat-box-canvas' + _vm.id,style:('transition: all 0;' +
    '-ms-transition: all 0;' +
    '-webkit-transition: all 0;' +
    '-moz-transition: all 0;' +
    '-o-transition: all 0;' +
    'transform-origin: ' +
    _vm.showTransformOriginValue +
    ';-ms-transform-origin: ' +
    _vm.showTransformOriginValue +
    ';-webkit-transform-origin: ' +
    _vm.showTransformOriginValue +
    ';-moz-transform-origin: ' +
    _vm.showTransformOriginValue +
    ';-o-transform-origin: ' +
    _vm.showTransformOriginValue +
    ';transform:' +
    _vm.showTransformValue +
    ';-ms-transform:' +
    _vm.showTransformValue +
    ';-webkit-transform:' +
    _vm.showTransformValue +
    ';-moz-transform:' +
    _vm.showTransformValue +
    ';-o-transform:' +
    _vm.showTransformValue +
    ';'),attrs:{"width":_vm.containerWidth,"height":_vm.containerHeight},on:{"tap":_vm.handelTap,"click":_vm.handelTap}},[_vm._v(" 很抱歉，您的浏览器版本过低，不支持 canvas 画布功能，请更换或升级浏览器后重试 "),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.supportCanvas),expression:"supportCanvas"}],staticClass:"iconfont",attrs:{"id":'canvas-icon-seat-unselected-highlight' + _vm.id}},[_vm._v("")]),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.supportCanvas),expression:"supportCanvas"}],staticClass:"iconfont",attrs:{"id":'canvas-icon-seat-selected' + _vm.id}},[_vm._v("")])])}
var staticRenderFns = []

export { render, staticRenderFns }