


















import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop } from "vue-property-decorator";
import MiniSeatMap from "@/components/SelectSeat/SeatSelector/MiniSeatMap.vue";
import SeatCart from "@/components/SelectSeat/SeatSelector/SeatCart.vue";
import CanvasSeatMap from "@/components/SelectSeat/SeatSelector/SeatMap/CanvasSeatMap.vue";
import { Toast } from "vant";
import { jsonp } from "vue-jsonp";
import { Row, Seat } from "@/store/modules/selectSeat";

@Component({
  components: { MiniSeatMap, CanvasSeatMap, SeatCart },
})
export default class SeatSelector extends Mixins(Mixin) {
  // 座位底图（JSONP 获取的）CDN 地址
  @Prop({
    type: String,
    default() {
      return "";
    },
  })
  cdnUrl!: string;

  // 演出票价信息
  @Prop({
    type: Object,
    default() {
      return {
        priceDtos: [],
      };
    },
  })
  showPrice!: good.ShowPriceDto;

  seatIdSeatMap: Map<number, Seat> = new Map(); // key：座位 Id；value：座位对象

  priceIdPriceMap: Map<number, good.PriceDto> = new Map(); // key：价格 ID；value：价格信息

  /**
   * 将底部购物车占用高度设置到 vuex 中
   */
  setBottomBarHeight(): void {
    let bottomHeight = 0;
    let seatCart = this.$refs["seat-cart"] as Vue;
    if (seatCart) {
      let submitBar = seatCart.$el.getElementsByClassName("van-submit-bar")[0];
      if (submitBar) {
        bottomHeight = submitBar.clientHeight * 2;
      }
    }
    this.SelectSeatModule.SET_bottomBarHeight(bottomHeight);
  }

  /**
   * 将 seat-selector-container 到屏幕顶部的距离设置到 vuex 中
   */
  setContainerTopOffset(): void {
    let containerTopOffset = 0;
    let seatSelectorContainer = document.getElementsByClassName(
      "seat-selector-container"
    )[0] as HTMLElement;
    if (seatSelectorContainer) {
      containerTopOffset = this.getTopOffset(seatSelectorContainer);
    }
    this.SelectSeatModule.SET_containerTopOffset(containerTopOffset);
  }

  /**
   * 获取元素顶部到屏幕顶部的距离
   */
  getTopOffset(seatSelectorContainer: HTMLElement): number {
    let actualTop = seatSelectorContainer.offsetTop;
    let parent = seatSelectorContainer.offsetParent as HTMLElement | null;
    while (parent) {
      actualTop += parent.offsetTop;
      parent = parent.offsetParent as HTMLElement | null;
    }
    return actualTop;
  }

  // 点击不同的分区 重新渲染座位
  seatSelect(sectionId: number): void {
    this.SelectSeatModule.SET_sectionId(sectionId);
    this.refresh();
  }

  /**
   * 清理历史状态、获取最新数据、根据新数据重新渲染页面
   */
  refresh(): void {
    // 清理历史状态
    this.clear();
    Toast.loading({
      duration: 0, // 持续展示 toast
      forbidClick: true,
    });
    // 将底部购物车占用高度设置到 vuex 中
    this.setBottomBarHeight();
    // 将 seat-selector-container 到屏幕顶部的距离设置到 vuex 中
    this.setContainerTopOffset();
    // 获取座位图数据，先从 CDN 取，如果取不到再从 API 获取
    let webCdnPath =
      this.SelectSectionModule.sectionIdWebCdnPathMap[
        this.SelectSeatModule.sectionId
      ];
    if (webCdnPath) {
      this.getSeatByJsonpFirst(webCdnPath);
    } else {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
      });
      this.$api.goodApi.show.getSectionInfo(
        this.SelectSeatModule.showId,
        {
          distributionChannelId: this.distributionChannelId,
          distributionSeriesId: this.distributionSeriesId,
        },
        ({ data }) => {
          let showSectionDtos = data.showSectionDtos;
          if (showSectionDtos && showSectionDtos.length > 0) {
            let sectionIdWebCdnPathMap: Record<string, string> = {};
            showSectionDtos.forEach((sectionDto) => {
              let sectionId = sectionDto.sectionId
                ? String(sectionDto.sectionId)
                : "";
              let webCdnPath = sectionDto.webCdnPath;
              if (sectionId && webCdnPath) {
                sectionIdWebCdnPathMap[sectionId] = webCdnPath;
              }
            });
            this.SelectSectionModule.SET_sectionIdWebCdnPathMap_PERSIST(
              sectionIdWebCdnPathMap
            );
            this.getSeatByJsonpFirst(
              sectionIdWebCdnPathMap[this.SelectSeatModule.sectionId]
            );
          }
          Toast.clear();
        }
      );
    }
  }

  getSeatByJsonpFirst(webCdnPath: string): void {
    jsonp(webCdnPath, {
      callbackQuery: "cb",
      callbackName: "jsonpCallback",
    }).then(
      ({
        code,
        state,
        data,
      }: {
        code: string;
        state: string;
        data: Array<good.SeatInfoDto>;
      }) => {
        if (code == "200" && state == "SUCCESS" && data) {
          this.getAvailableSeat(data);
        } else {
          this.getSeatInfoFromApi();
        }
      }
    );
  }

  /**
   * 通过 API 获取座位图
   */
  getSeatInfoFromApi(): void {
    this.$api.goodApi.seat.getSeatsInfo(
      this.SelectSeatModule.sectionId,
      this.SelectSeatModule.showId,
      ({ data }) => {
        this.getAvailableSeat(data);
      }
    );
  }

  getAvailableSeat(data: Array<good.SeatInfoDto>): void {
    if (data && data.length > 0) {
      this.$api.goodApi.seat.getAvailableSeat(
        this.SelectSeatModule.productId,
        this.SelectSeatModule.sectionId,
        this.SelectSeatModule.showId,
        {
          distributionChannelId: this.distributionChannelId,
          distributionSeriesId: this.distributionSeriesId,
        },
        (res) => {
          let availableArray = res.data ? res.data : [];
          let priceIdPriceMap: Map<number, good.PriceDto> = new Map();
          if (this.showPrice.priceDtos) {
            this.showPrice.priceDtos.forEach((price) => {
              let ticketPriceId = price.ticketPriceId;
              if (
                ticketPriceId != undefined &&
                !priceIdPriceMap.has(ticketPriceId)
              ) {
                priceIdPriceMap.set(ticketPriceId, price);
              }
            });
          }
          this.priceIdPriceMap = priceIdPriceMap;
          this.SelectSeatModule.SET_rowArr(
            this.genRowArr(data, availableArray)
          );
          this.refreshSubcomponent();
        }
      );
    }
    Toast.clear();
  }

  /**
   * 清理历史状态
   */
  clear(): void {
    this.SelectSeatModule.SET_rowArr([]);
    this.SelectSeatModule.clearSelectedSeat();
    this.refreshSubcomponent();
  }

  /**
   * 刷新子组件
   */
  refreshSubcomponent(): void {
    if (this.$refs["seat-map"]) {
      (this.$refs["seat-map"] as CanvasSeatMap).refresh();
    }
    if (this.$refs["mini-seat-map"]) {
      (this.$refs["mini-seat-map"] as MiniSeatMap).refresh();
    }
  }

  /**
   * 根据后端数据，组装用于渲染座位图的 Array<Row>
   */
  genRowArr(
    data: Array<good.SeatInfoDto>,
    availableArray: Array<number>
  ): Array<Row> {
    let newRowArr: Array<Row> = [];
    let maxColumnCount = 0; // 最大的坐位列数
    let minX = 100000; // 最小的坐位坐标
    let currentAvailablePriceIds: Array<number> = []; // 循环到当前，存在可选坐位的票档 ID
    let customStageXmax = 0; // 自定义舞台最大 X 坐标
    let customStageXmin = 100000; // 自定义舞台最小 X 坐标
    let customStageYmax = 0; // 自定义舞台最大 Y 坐标
    let customStageYmin = 100000; // 自定义舞台最小 Y 坐标
    data.forEach((seatInfo) => {
      if (seatInfo.sid != undefined) {
        let availableIndex = seatInfo.i || seatInfo.i == 0 ? seatInfo.i : -1;
        let seatId = seatInfo.sid; // 座位 ID
        let seatType = seatInfo.t; // 座位类型，1 座位，2 舞台
        let y = seatInfo.y;
        let x = seatInfo.x;
        if (
          ((seatType == 1 && seatId != undefined) || seatType == 2) &&
          y != undefined &&
          x != undefined
        ) {
          if (seatType == 2) {
            if (x > customStageXmax) {
              customStageXmax = x;
            }
            if (x < customStageXmin) {
              customStageXmin = x;
            }
            if (y > customStageYmax) {
              customStageYmax = y;
            }
            if (y < customStageYmin) {
              customStageYmin = y;
            }
            if (!this.SelectSeatModule.isCustomStage) {
              // 如果要绘制后端自定义的舞台，则不展示固定舞台
              this.SelectSeatModule.SET_isCustomStage(true);
            }
          }
          // 是座位且座位 ID 有效，或是舞台
          let isAvailable =
            availableIndex >= 0 &&
            availableIndex < availableArray.length &&
            availableArray[availableIndex] == 1; // 是否可选，前边的比较是为了防止数组越界
          let priceId = seatInfo.p; // 票档 ID
          if (
            priceId != undefined &&
            isAvailable &&
            currentAvailablePriceIds.indexOf(priceId) < 0
          ) {
            currentAvailablePriceIds.push(priceId);
          }
          let currentColumnCount = this.setRowArr(
            newRowArr,
            y, // 第几行
            x, // 第几列
            seatId, // 座位 ID
            seatInfo.d, // 座位描述
            seatInfo.b, // 坐位备注
            isAvailable, // 是否可选
            priceId, // 票价 Id
            seatType // 座位类型，1 座位，2 舞台
          );
          if (currentColumnCount > maxColumnCount) {
            maxColumnCount = currentColumnCount;
          }
          if (x < minX) {
            minX = x;
          }
        }
      }
    });
    /**
     * 最左侧坐位 columnCount 为 0 时（从 x == 0 开始绘制）第一列的坐位会只画一半
     * 左边加一列空白过道来避免这种情况，同时也让座位图左侧坐位不要挨着屏幕边框
     */
    newRowArr.forEach((currentRow) => {
      if (currentRow) {
        currentRow.cols.splice(0, 0, new Seat());
        for (let i = 1; i < currentRow.cols.length; i++) {
          let seat = currentRow.cols[i];
          if (seat) {
            seat.columnCount = i;
          }
        }
      }
    });
    // const leftRedundant = minX > 1 ? minX - 1 : 0;
    this.SelectSeatModule.SET_maxColumnCount(maxColumnCount + 2 - minX);
    // 用 minX 修正下自定义舞台最大 X 坐标，因为这些是会被去掉的坐位
    this.SelectSeatModule.SET_customStageXmax(customStageXmax - minX + 2);
    // 用 minX 修正下自定义舞台最小 X 坐标，因为这些是会被去掉的坐位
    this.SelectSeatModule.SET_customStageXmin(customStageXmin - minX + 2);
    this.SelectSeatModule.SET_customStageYmax(customStageYmax);
    this.SelectSeatModule.SET_customStageYmin(customStageYmin);
    // 先去掉坐位左侧多余的非坐位项，再填充坐位右侧的缺失项
    this.fixSeatArray(newRowArr, minX - 1);
    this.SelectSeatModule.SET_hasAvailableSeatPriceIds(
      currentAvailablePriceIds
    );
    return newRowArr;
  }

  /**
   * 根据 X、Y 坐标和座位信息，将一个座位放到二维数组中
   * 顺便设置座位 ID 与座位对象的 Map
   */
  setRowArr(
    rowArr: Array<Row>,
    rowCount: number,
    columnCount: number,
    seatId?: number,
    description?: string,
    remark?: string,
    available?: boolean,
    priceId?: number,
    type?: number
  ): number {
    if (seatId != undefined) {
      const row = rowArr[rowCount] || new Row(rowCount);
      const price =
        priceId == undefined ? undefined : this.priceIdPriceMap.get(priceId);
      let seat = new Seat(
        seatId,
        description || "",
        remark || "",
        available == undefined ? false : available,
        priceId,
        rowCount,
        columnCount,
        type
      );
      seat.price = price || {};
      this.seatIdSeatMap.set(seatId, seat);
      row.cols[columnCount] = seat;
      rowArr[rowCount] = row;
      return row.cols.length;
    }
    return 0;
  }

  /**
   * 1）先去掉坐位左侧多余的非坐位项
   * 2）填充缺失项：
   * 2.1 让每一行都有同样数量的列，每一行都补充至最大行的长度
   * 2.2 将 Row 数组中为 undefined 的元素设置为一个新的 Row 对象
   *
   * @param newRowArr 行数组
   * @param leftRedundant 左侧多余的项的数量，需要先删除
   */
  fixSeatArray(newRowArr: Array<Row | null>, leftRedundant: number): void {
    if (leftRedundant > 0) {
      // 先去掉坐位左侧多余的非坐位项
      newRowArr.forEach((currentRow) => {
        if (currentRow) {
          let cols = currentRow.cols;
          currentRow.cols = cols.slice(leftRedundant);
          for (let i = 0; i < currentRow.cols.length; i++) {
            let seat = currentRow.cols[i];
            if (seat) {
              seat.columnCount = i;
            }
          }
        }
      });
    }
    if (!this.SelectSeatModule.isCustomStage) {
      // 如果没有自定义舞台，则会使用固定舞台，此时需增加两行过道以留出固定舞台的位置
      newRowArr.splice(0, 0, null, null);
      newRowArr.forEach((currentRow, index) => {
        if (currentRow) {
          currentRow.cols.forEach((seat) => {
            seat.rowCount = index;
          });
        }
      });
    }
    // 填充缺失项
    for (let i = 0; i < newRowArr.length; i++) {
      let currentRow = newRowArr[i];
      if (currentRow) {
        // 补齐缺失的列
        let currentColumnLength = currentRow.cols.length;
        let gap = this.SelectSeatModule.maxColumnCount - currentColumnLength;
        if (gap > 0) {
          for (let j = 0; j < gap; j++) {
            currentRow.cols[currentColumnLength + j] = new Seat();
          }
        }
      } else {
        // 过道的行
        let row = new Row(0);
        row.isSide = true;
        newRowArr[i] = row;
      }
    }
  }

  submit(data: order.AliSliderCaptchaDto): void {
    this.$emit("submit", data);
  }
}
